
import {defineComponent, ref, onMounted, watch, reactive} from 'vue';
import {useRoute} from 'vue-router';
import {format} from 'date-fns';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import partnerAPI from '@/service/partnerAPI';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';

export type eventListParams = {
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'eventList',
  components: {
    Container,
    Pagination,
    IconBase,
    CheckCircleIcon,
    RemoveIcon
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;
    const eventList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    }) as any;

    const filterParams = reactive<eventListParams>({
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const handleAllCheck = (e) => {
      eventList.value.data = eventList.value.data.map(data => {
        return {
          ...data,
          checked: e.target.checked
        };
      });
    };

    const handleDelete = async () => {
      try {
        const checkedData = eventList.value.data.filter(d => d.checked === true).map(d => d.eventId) || [];
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.');
          return;
        }

        await  partnerAPI.adminEvent.adminEventDelete({
          eventIdArray: checkedData
        })

        fetchEventList();
        alert('삭제 되었습니다.');

      } catch (e) {
        console.error(e);
      }
    };

    const handleCheck = (e, idx) => {
      console.log('cc', e.target.checked, idx);
      eventList.value.data[idx].checked = e.target.checked;
    };


    const handleClickRow = (item) => {
      router.push({
        path: '/app/event/register',
        query: {
          eventId: item.eventId,
        },
      });
    }

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };
    const handleClickSearch = () => {
      filterParams.page = 0;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchMemberList();
      router.push({
        path: window.location.pathname,
        query: {
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10
        },
      });
    };

    const getAppliedFilterParams = () => {
      return {
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      };
    };

    const fetchEventList = async () => {
      try {
        const {data} = await partnerAPI.adminEvent.adminEventList(
          getAppliedFilterParams()
        );
        eventList.value = data as any;
      } catch ({response}) {
        console.error(response.data);
        alert(response.data.message);
      }
    };


    onMounted(fetchEventList);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchEventList();
    });


    return {
      filterParams,
      eventList,
      format,
      handleDelete,
      handleCheck,
      handleAllCheck,
      handleChangePage,
      handleChangePageSize,
      handleClickSearch,
      handleClickRow,
      executeSearch,
      fetchEventList
    };
  },
});
