<template>
  <div class="flex w-full justify-end items-center">
    <Pagination
        v-model="page"
        :records="total"
        :per-page="pageSize"
        @paginate="(page) => onChange(page)"
        :options="{
        texts: {
          count: '{count}개 중 {from}~{to}|{count}개|1개',
        },
      }"
    />
    <div
        class="inline-flex text-sm items-center"
        :style="{ border: '1px solid #DDDDDD' }"
    >
      <select
          class="Pagination__pageSizeSelect"
          name=""
          id=""
          ref="pageSizeSelect"
          @change="(e) => handleChangePageSize(e.target.value)"
      >
        <option
            v-for="(pageSize, index) in pageSizes"
            :key="index"
            :value="pageSize"
        >
          {{ pageSize }}
        </option>
      </select>
      <button disabled></button>
    </div>
  </div>
</template>

<script>
import Pagination from 'v-pagination-3';
import { ref, watch } from 'vue';

export default {
  components: {
    Pagination,
  },
  props: {
    initialPage: Number,
    pageSize: Number,
    total: Number,
    onChange: Function, // (page: number) => void
    onChangePageSize: Function, // (pageSize: number) => void
  },
  methods: {},
  setup(props) {
    const page = ref(props.initialPage + 1);
    const pageSizes = [10, 20, 30, 40, 50];

    watch(() => props.initialPage, (newPage) => {
      page.value = newPage + 1;
    });

    const handleChangePageSize = (pageSize) => {
      props.onChangePageSize(pageSize);
    };

    return {
      page,
      pageSizes,
      handleChangePageSize,
    };
  },
};
</script>

<style lang="scss">
/* 외부 컴포넌트에 적용할 스타일시트. global로 작성한다 */
.VuePagination {
  width: 100%;
  display: flex;
  & > nav {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 1rem;
  }
}

.VuePagination__count {
  margin-right: auto;
}

.VuePagination__pagination {
  display: flex;
}

.VuePagination__pagination-item {
  display: inline-flex;
  min-width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  color: #999999;

  & > button {
    width: 100%;
    height: 100%;
  }

  &.active {
    color: #000;
    font-weight: bold;
  }

  /* 한페이지 앞 이동  */
  &.VuePagination__pagination-item-prev-page {
    border: 1px solid #dddddd;
    color: transparent;
    background: url('./ic_carrot_left.svg') no-repeat center;
    background-size: 8px 12px;

    &.disalbed {
      /* TODO: */
    }
  }

  /* 한세트 세트 앞 이동 */
  &.VuePagination__pagination-item-prev-chunk {
    position: relative;
    color: transparent;
    background-position: 60% center;

    &::before {
      z-index: 2;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: url('./ic_carrot_left.svg') no-repeat;
      background-size: 8px 12px;
      background-position: 35% center;
    }
  }

  /* 한페이지 앞 이동  */
  &.VuePagination__pagination-item-next-page {
    border: 1px solid #dddddd;
    color: transparent;
    background: url('./ic_carrot_left.svg') no-repeat center;
    transform: rotate(180deg);
    background-size: 8px 12px;

    &.disalbed {
      /* TODO: */
    }
  }

  /* 한세트 세트 앞 이동 */
  &.VuePagination__pagination-item-next-chunk {
    position: relative;
    color: transparent;
    background-position: 60% center;

    &::before {
      z-index: 2;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: url('./ic_carrot_left.svg') no-repeat;
      transform: rotate(0deg);
      background-size: 8px 12px;
      background-position: 35% center;
    }
  }
}

.Pagination__pageSizeSelect {
  appearance: none;
  width: 32px;
  height: 30px;
  text-indent: 0.5rem;

  &:focus {
    outline: none;
  }

  & + button {
    width: 25px;
    height: 30px;
    content: '';
    right: 0;
    background: url('./ic_updown.svg') no-repeat center;
    border-left: 1px solid #ddd;

    &:hover {
      cursor: default;
    }
  }
}
</style>
