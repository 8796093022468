<template>
  <Container>
    <p class="text-sm">전체 {{ eventList.total }}건</p>
    <table
      class="ListTable table-fixed w-full text-center text-xs mt-3"
    >
      <thead class="bg-gray-200">
      <tr class="h-10">
        <th class="w-10 border-t border-black border-b">
          <div class="flex items-center pl-2 justify-center">
            <input
              type="checkbox"
              class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
              @click="handleAllCheck"
            />
            No.
          </div>
        </th>
        <th class="w-10 border-t border-black border-b">상태</th>
        <th class="w-20 border-t border-black border-b">EVENT Code</th>
        <th class="w-32 border-t border-black border-b">배너 이미지</th>
        <th class="w-30 border-t border-black border-b">이벤트 타이틀</th>
        <th class="w-40 border-t border-black border-b">이벤트 설명</th>
        <th class="w-30 border-t border-black border-b">이벤트 기간</th>
        <th class="w-10 border-t border-black border-b">참가 셀러수</th>
        <th class="w-10 border-t border-black border-b">작품수</th>
      </tr>
      </thead>
      <tbody>
      <tr
        class="border-b border-grey-300 text-center cursor-pointer"
        v-for="(item, index) in eventList.data"
        :key="index"
      >
        <!-- No check -->
        <td class="bg-gray-100">
          <div class="flex items-center justify-center">
            <input
              type="checkbox"
              class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
              :checked="item.checked"
              @click="(e) => handleCheck(e, index)"
            />
            {{ item.eventId || '-' }}
          </div>
        </td>

        <!-- 상태 -->
        <td class="bg-gray-100" @click="handleClickRow(item)">
          {{ item.eventStatus }}
        </td>

        <!-- EVENT Code -->
        <td class="bg-gray-100 px-2" @click="handleClickRow(item)">
          <p class="break-all">
            {{ item.code || '-' }}
          </p>
        </td>

        <!-- 배너 이미지 -->
        <td @click="handleClickRow(item)">
          <div
            class="ProductImageItem w-full h-14 bg-gray-200 mr-1 bg-cover bg-center relative flex items-center justify-center"
            :style="{ backgroundImage: `url(${item.bannerUrl1})` }"
          ></div>
        </td>

        <!-- 이벤트 타이틀 -->
        <td class="px-2" @click="handleClickRow(item)">
          <p class="truncate">{{ item.title }}</p>
        </td>

        <!-- 이벤트 설명 -->
        <td class="px-2" @click="handleClickRow(item)">
          <p class="truncate">{{ item.description || '-' }}</p>
        </td>

        <!-- 이벤트 기간-->
        <td @click="handleClickRow(item)">
          <span v-if="item.startDate">
            {{ format(new Date(item.startDate), 'yyyy/MM/dd HH:mm') }}
          </span>
          <span v-else>-</span>
          <span v-if="item.endDate">
            ~ {{ format(new Date(item.endDate), 'yyyy/MM/dd HH:mm') }}
          </span>
          <span v-else>-</span>
        </td>

        <!-- 참가 셀러수 -->
        <td @click="handleClickRow(item)">
          {{ item.sellersCount || '0' }}개
        </td>

        <!-- 작품수 -->
        <td @click="handleClickRow(item)">
          {{ item.productsCount || '0' }}점
        </td>
      </tr>
      </tbody>
    </table>

    <div class="mt-4 flex items-center event-list-bottom">
      <button
        class="w-40 h-9 button-shadow inline-flex text-red-500 items-center justify-center border border-red-500 text-sm bg-white"
        @click="handleDelete"
      >
        <IconBase>
          <RemoveIcon></RemoveIcon>
        </IconBase>
        <span class="ml-2">삭제</span>
      </button>
      <div class="table-bottom-line mx-3"></div>
      <button
        class="w-64 h-9 button-shadow inline-flex text-white items-center justify-center bg-black text-sm mr-3"
        @click="() => $router.push('/app/event/register')"
      >
        <IconBase>
          <CheckCircleIcon></CheckCircleIcon>
        </IconBase>
        <span class="ml-2">새 이벤트 등록하기</span>
      </button>
      <Pagination
        :initialPage="filterParams.page"
        :pageSize="parseInt(filterParams.pageSize)"
        :onChange="handleChangePage"
        :onChangePageSize="handleChangePageSize"
        :total="eventList.total"
      ></Pagination>
    </div>
  </Container>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, watch, reactive} from 'vue';
import {useRoute} from 'vue-router';
import {format} from 'date-fns';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import partnerAPI from '@/service/partnerAPI';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';

export type eventListParams = {
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'eventList',
  components: {
    Container,
    Pagination,
    IconBase,
    CheckCircleIcon,
    RemoveIcon
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;
    const eventList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    }) as any;

    const filterParams = reactive<eventListParams>({
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const handleAllCheck = (e) => {
      eventList.value.data = eventList.value.data.map(data => {
        return {
          ...data,
          checked: e.target.checked
        };
      });
    };

    const handleDelete = async () => {
      try {
        const checkedData = eventList.value.data.filter(d => d.checked === true).map(d => d.eventId) || [];
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.');
          return;
        }

        await  partnerAPI.adminEvent.adminEventDelete({
          eventIdArray: checkedData
        })

        fetchEventList();
        alert('삭제 되었습니다.');

      } catch (e) {
        console.error(e);
      }
    };

    const handleCheck = (e, idx) => {
      console.log('cc', e.target.checked, idx);
      eventList.value.data[idx].checked = e.target.checked;
    };


    const handleClickRow = (item) => {
      router.push({
        path: '/app/event/register',
        query: {
          eventId: item.eventId,
        },
      });
    }

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };
    const handleClickSearch = () => {
      filterParams.page = 0;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchMemberList();
      router.push({
        path: window.location.pathname,
        query: {
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10
        },
      });
    };

    const getAppliedFilterParams = () => {
      return {
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      };
    };

    const fetchEventList = async () => {
      try {
        const {data} = await partnerAPI.adminEvent.adminEventList(
          getAppliedFilterParams()
        );
        eventList.value = data as any;
      } catch ({response}) {
        console.error(response.data);
        alert(response.data.message);
      }
    };


    onMounted(fetchEventList);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchEventList();
    });


    return {
      filterParams,
      eventList,
      format,
      handleDelete,
      handleCheck,
      handleAllCheck,
      handleChangePage,
      handleChangePageSize,
      handleClickSearch,
      handleClickRow,
      executeSearch,
      fetchEventList
    };
  },
});
</script>

<style lang="scss">
@import "../../../assets/style/label";

.excel-download {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  color: #2563eb;
  background-color: #fff;
  border-color: #D9D9D9;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;

  &:checked {
    background-image: url('../../../assets/input_check-primary.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.table-bottom-line {
  width: 1px;
  height: 18px;
  background: #C4C4C4;;
}

.event-list-bottom {
  .VuePagination__count {
    display: none;
  }
}
</style>
